<template>
  <div class="h-full">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">{{ data.loading ? 'Loading...' : 'Data IPL' }}</p>
        </div>
      </div>
      <div class="h-full">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky w-full border-collapse">
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-12">#</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-14">Action</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-24">No. Generate</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-26">No. IPL</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-20">Due Date</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-40">Keterangan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-24">Total Nilai</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-20">Status</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-24">No Jurnal</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-24">No invoice</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-20">Created By</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-20">Created At</th>
              </tr>
            </thead>
            <tbody v-for="group in data.items" :key="group.uuid">
              <!--group-->
              <tr class="cursor-pointer hover-bg-primary-transparent-10" @click="group.expanded = !group.expanded">
                <td class="border border-solid d-theme-border-grey-light p-2 font-medium" colspan="12">
                  <div class="flex items-center">
                    <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                    <span class="font-bold text-sm">Unit: {{ group.name }}</span>
                  </div>
                </td>
              </tr>
              <!--child-->
              <template v-if="group.expanded">
                <tr v-for="(child, index) in group.child" :key="index" @dblclick="showModalDetail(child)">
                  <td class="text-xs border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                    <span class="ml-6">{{ index + 1 }}</span>
                  </td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                    <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs">
                      <vs-button class="py-2 px-3 small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                      <vs-dropdown-menu>
                        <vs-dropdown-item class="p-1" @click="showModalDetail(child)">View Detail</vs-dropdown-item>
                        <vs-dropdown-item class="p-1" :disabled="child.id_invoice == null" @click="printKontrak(child.id_invoice)">View Invoice</vs-dropdown-item>
                        <vs-dropdown-item class="p-1" :disabled="child.id_jurnal" @click="showModalEdit(child)" v-if="$can('update_penerimaan_ipl_termin')">Edit</vs-dropdown-item>
                        <vs-dropdown-item class="p-1" :disabled="child.id_jurnal" @click="confirmDelete(child.id)" v-if="$can('delete_penerimaan_ipl_termin')"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.no_generate }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.no_ipl }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.tgl_due }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.keterangan }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ child.total_nilai | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ child.status }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ child.no_jurnal || '-' }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ child.no_invoice || '-' }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.created_by }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ child.created_at }}</td>
                </tr>
                <tr class="d-theme-dark-bg font-semibold">
                  <td colspan="6" class="border border-solid d-theme-border-grey-light p-2 text-xs text-left"></td>
                  <td class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.subTotal | idr }}</td>
                </tr>
              </template>
            </tbody>
            <!-----------TABLE FOOT------------>
            <tfoot v-if="data.items.length > 0">
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right">{{ summary.grandTotalNilai | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-sm"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <TerminAdd :isActive.sync="modalAdd.active" @success="getData"/>
    <TerminEdit :isActive.sync="modalEdit.active" :idIpl="modalEdit.idIpl" @success="getData"/>
    <TerminShow :isActive.sync="modalDetail.active" :idIpl="modalDetail.idIpl"/>
  </div>
</template>

<script>
import IplRepository from '@/repositories/estate/ipl-repository'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'TerminsSection',
  components: {
    TerminAdd: () => import('@/views/pages/estate/ipl/termins/TerminAdd'),
    TerminEdit: () => import('@/views/pages/estate/ipl/termins/TerminEdit'),
    TerminShow: () => import('@/views/pages/estate/ipl/termins/TerminShow')
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.estate.ipl.headerSection
    },
    summary () {
      return {
        grandTotalNilai: _.sumBy(this.data.items, item => item.subTotal)
      }
    }
  },
  mixins: [printoutMixin],
  data () {
    return {
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        idIpl: null
      },
      modalDetail: {
        active: false,
        idIpl: null
      },
      data: {
        loading: false,
        items: []
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('estate/ipl/SET_TERMINS_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData () {
      this.data.loading = true

      const params = {
        id_pengelola: this.dataHeaderSection.filter.id_pengelola,
        search: this.dataHeaderSection.filter.search,
        periode_awal: this.dataHeaderSection.filter.periode_awal,
        periode_akhir: this.dataHeaderSection.filter.periode_akhir
      }
      IplRepository.getTermins(params)
        .then(response => {
          this.data.items = this.buildGroups(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    buildGroups (data) {
      const generateGroupChild = (idPropertiUnit) => {
        return _.filter(data, item => item.id_properti_unit === idPropertiUnit)
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.id_properti_unit), item => {
          const child = generateGroupChild(item.id_properti_unit)
          return {
            uuid: uuid(),
            name: `${item.kode_proyek_unit} | ${item.no_unit}`,
            child: child,
            subTotal: _.sumBy(child, item => item.total_nilai),
            expanded: true
          }
        })
      }

      return generateGroups()
    },

    delete (id) {
      IplRepository.deleteTermin(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    showModalAdd () {
      this.modalAdd.active = true
    },

    showModalEdit (item) {
      this.modalEdit.idIpl = item.id
      this.modalEdit.active = true
    },

    showModalDetail (item) {
      this.modalDetail.idIpl = item.id
      this.modalDetail.active = true
    },

    printKontrak (id) {
      this.showPrintOutFile('IPL_CUSTOMER', { id_invoice: id })
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 287px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 310px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 257px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 273px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 295px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 243px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 256px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 279px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 225px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 210px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 233px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 180px);
}

/*table sticky*/
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
