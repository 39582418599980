const getDefaultState = () => {
  return {
    headerSection: {},
    terminsSection: {
      loading: false,
      items: []
    }
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_HEADER_SECTION (state, payload) {
      state.headerSection = payload
    },
    SET_TERMINS_SECTION (state, payload) {
      state.terminsSection = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
