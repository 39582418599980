<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ header ------------->
    <div class="flex-none">
      <div class="p-2 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex flex-wrap space-x-3">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Pengelola</label>
            <vs-select class="vs-select-small" v-model="headerSection.filter.id_pengelola">
              <vs-select-item class="vs-select-small" v-for="(it, index) in listPengelola" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Due Period</label>
            <vx-input-group>
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="headerSection.filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="headerSection.filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full text-sm" type="text" placeholder="Search" v-model="headerSection.filter.search" @keyup.enter="filter"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-6 py-3 text-xs text-center" color="primary" @click="filter" :disabled="!headerSection.filter.id_pengelola || dataTerminsSection.loading">{{ dataTerminsSection.loading ? 'Filtering..' : 'Filter' }}</vs-button>
          </div>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full sm:w-12/12 d-theme-dark-bg">
          <TerminsSection ref="terminsSection"/>
        </div>
      </div>
    </div>

    <!------------ footer ------------->
    <div class="flex-none vx-card p-2 rounded-none">
      <div class="flex flex-wrap items-center">
        <div class="sm:w-4/12 w-full">
          <div class="flex items-center">
            <vs-button color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-plus" @click="$refs.terminsSection.showModalAdd()" v-if="$can('create_penerimaan_ipl_termin')">Generate Termin</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import TerminsSection from '@/views/pages/estate/ipl/termins/TerminsSection'
import moduleIpl from '@/store/modules/estate/ipl/ipl.store'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'Ipl',
  components: {
    TerminsSection,
    flatPickr
  },
  mounted () {
    this.getPengelola()
  },
  computed: {
    dataTerminsSection () {
      return this.$store.state.estate.ipl.terminsSection
    }
  },
  data () {
    return {
      listPengelola: [],
      headerSection: {
        filter: {
          id_pengelola: null,
          search: null,
          periode_awal: moment().startOf('year').format('YYYY-MM-DD'),
          periode_akhir: moment().endOf('year').format('YYYY-MM-DD')
        }
      }
    }
  },
  watch: {
    headerSection: {
      deep: true,
      handler (value) {
        this.commitHeaderSectionToStore(value)
      }
    }
  },
  methods: {
    getPengelola () {
      const params = {
        externalFilter: JSON.stringify({
          is_pengelola_ipl: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.listPengelola = response.data.data
          if (this.listPengelola.length > 0) {
            this.headerSection.filter.id_pengelola = this.listPengelola[0].id
            setTimeout(() => {
              this.filter()
            }, 100)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    filter () {
      this.$refs.terminsSection.refresh()
    },

    commitHeaderSectionToStore (value) {
      this.$store.commit('estate/ipl/SET_HEADER_SECTION', _.cloneDeep(value))
    }
  },
  created () {
    this.commitHeaderSectionToStore(this.headerSection)
  },
  beforeCreate () {
    this.$store.registerModule(['estate', 'ipl'], moduleIpl)
  },
  beforeDestroy () {
    this.$store.commit('estate/ipl/RESET_STATE')
    this.$store.unregisterModule(['estate', 'ipl'])
  }
}
</script>
